exports.components = {
  "component---src-components-page-js": () => import("./../../../src/components/page.js" /* webpackChunkName: "component---src-components-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mdx-about-mdx": () => import("./../../../src/pages/mdx/about.mdx" /* webpackChunkName: "component---src-pages-mdx-about-mdx" */),
  "component---src-pages-mdx-faq-mdx": () => import("./../../../src/pages/mdx/faq.mdx" /* webpackChunkName: "component---src-pages-mdx-faq-mdx" */),
  "component---src-pages-mdx-get-involved-mdx": () => import("./../../../src/pages/mdx/get-involved.mdx" /* webpackChunkName: "component---src-pages-mdx-get-involved-mdx" */),
  "component---src-pages-mdx-introduction-mdx": () => import("./../../../src/pages/mdx/introduction.mdx" /* webpackChunkName: "component---src-pages-mdx-introduction-mdx" */),
  "component---src-pages-mdx-organisations-mdx": () => import("./../../../src/pages/mdx/organisations.mdx" /* webpackChunkName: "component---src-pages-mdx-organisations-mdx" */),
  "component---src-pages-mdx-resources-mdx": () => import("./../../../src/pages/mdx/resources.mdx" /* webpackChunkName: "component---src-pages-mdx-resources-mdx" */)
}

